import clsx from 'clsx';
import React from 'react';
import styles from './index.module.css';

export interface FormSelectProps
  extends React.HTMLAttributes<HTMLSelectElement> {
  disabled?: boolean;
  error?: boolean;
}

const FormSelect = React.forwardRef((props: FormSelectProps, ref?: any) => {
  let className = clsx(
    `border outline-none text-text rounded-lg text-sm px-2.5 py-1.5 cursor-pointer relative appearance-none`,
    props.error ? 'border-red-500 bg-red-100' : 'border-border',
    props.disabled ? 'opacity-50 pointer-events-none' : undefined,
    styles.select,
    props.className
  );

  return (
    <select
      {...{ ...props, error: undefined, disabled: undefined }}
      className={className}
      ref={ref}
    >
      {props.children}
    </select>
  );
});

export default FormSelect;
