import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../../context/auth-context';
import { homeRoute } from '../../utils/routes';

function NotAuthGuard(props: { children: React.ReactNode }) {
  const { apiKey } = useContext(AuthContext);

  if (apiKey) {
    return <Navigate to={homeRoute} />;
  } else {
    return <>{props.children}</>;
  }
}

export default NotAuthGuard;
