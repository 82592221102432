import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getQrCodes } from '../../../api/qrcode';
import HomeContext from '../../../context/home-context';
import Button from '../../shared/button';
import SearchInput from '../../shared/form/search';
import Notification from '../../shared/notification';
import Title from '../../shared/title';
import QrCodeListItem from './item';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function QrCodeList(props: Props) {
  const { t } = useTranslation('home');
  const commonTranslations = useTranslation('common');
  const { qrCodes, setQrCodes } = useContext(HomeContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [search, setSearch] = useState<string | undefined>();

  useEffect(() => {
    setTimeout(() => {
      loadNext();
    }, 100);
  }, [search]);

  async function loadNext(loadMore?: boolean) {
    setError(undefined);
    setLoading(true);
    try {
      const data = await getQrCodes({
        startKey: loadMore ? qrCodes!.startKey : undefined,
        search,
      });

      console.log('loaded', data);

      if (loadMore) {
        setQrCodes({ ...data, items: qrCodes!.items.concat(...data.items) });
      } else {
        setQrCodes(data);
      }
    } catch (err: any) {
      setError(t('list.errors.list')!);
    }
    setLoading(false);
  }

  function onSearch(s: string) {
    setSearch(s === '' ? undefined : s);
  }

  function onLoadMore() {
    loadNext(true);
  }

  return (
    <div
      {...props}
      className={clsx('bg-background p-6 rounded-xl', props.className)}
    >
      <Title className="mb-4">{t('list.title')}</Title>
      <div className="mb-5">
        <SearchInput placeholder={t('list.search')} onSearch={onSearch} />
      </div>
      {error && <Notification color="danger">{error}</Notification>}
      {qrCodes && qrCodes.items.length < 1 && <div>{t('list.noResults')}</div>}

      {qrCodes && qrCodes.items.length > 0 && (
        <div className="grid grid-cols-4 gap-8 max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">
          {qrCodes.items.map((item) => (
            <QrCodeListItem key={item.PK} item={item} />
          ))}
          {qrCodes.startKey && (
            <div className="col-span-4 flex justify-center">
              <Button onClick={onLoadMore} disabled={loading}>
                {commonTranslations.t('loadMore')}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default QrCodeList;
