import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../../context/auth-context';
import { loginRoute } from '../../utils/routes';

function AuthGuard(props: { children: React.ReactNode }) {
  const { apiKey } = useContext(AuthContext);

  if (!apiKey) {
    return <Navigate to={loginRoute} />;
  } else {
    return <>{props.children}</>;
  }
}

export default AuthGuard;
