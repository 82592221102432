import React, { useState } from 'react';
import FormInput, { FormInputProps } from '../input';
import EyeIcon from './eye.svg';
// import { ReactComponent as EyeIcon } from './eye.svg';
import IconInputWrapper from '../icon-input-wrapper';

// interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

const PasswordFormInput = React.forwardRef(
  (props: FormInputProps, ref?: any) => {
    const [show, setShow] = useState(false);

    const onToggle = () => {
      setShow(!show);
    };

    return (
      <IconInputWrapper
        icon={EyeIcon}
        onIconClick={onToggle}
        iconClassName="top-2.5 right-2.5"
      >
        <FormInput
          {...props}
          ref={ref}
          type={show ? 'text' : 'password'}
          className="w-full pr-10"
        />
      </IconInputWrapper>
      // <div className="relative">
      //   <FormInput
      //     {...props}
      //     ref={ref}
      //     type={show ? 'text' : 'password'}
      //     className="w-full"
      //   />
      //   <div className="absolute right-2.5 top-2.5" onClick={onToggle}>
      //     <EyeIcon />
      //     {/* <Image src={Eye} alt="eye" width="25" height="25" /> */}
      //   </div>
      // </div>
    );
  }
);

export default PasswordFormInput;
