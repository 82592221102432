import React, { useRef } from 'react';
import IconInputWrapper from '../icon-input-wrapper';
import FormInput, { FormInputProps } from '../input';
import SearchIcon from './search.svg';

const SearchInput = React.forwardRef(
  (
    props: FormInputProps & { onSearch: (search: string) => void },
    ref?: any
  ) => {
    const inputRef = useRef<any>();
    const timeoutRef = useRef<any>();

    function onInput(event: any) {
      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        onSearch();
      }, +process.env.REACT_APP_SEARCH_TIMEOUT!);
    }

    function onSearch() {
      props.onSearch(
        inputRef.current.value === '' ? undefined : inputRef.current.value
      );

      clearTimeout(timeoutRef.current);
    }

    return (
      <IconInputWrapper icon={SearchIcon} iconClassName="top-3.5 left-3.5">
        <FormInput
          {...props}
          ref={inputRef}
          className="w-full pl-12 py-[0.94rem] rounded-lg border-0"
          onInput={onInput}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSearch();
            }
          }}
        />
      </IconInputWrapper>
    );
  }
);

export default SearchInput;
