import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import HomeContext from '../../../../context/home-context';
import FormGroup from '../../../shared/form/group';
import FormImageUpload from '../../../shared/form/image-upload';
import FormLabel from '../../../shared/form/label';
import QrCodeFormContainer from '../container';

interface Props {}

function QrCodeFormLayers(props: Props) {
  const { t } = useTranslation('home');
  const { loading } = useContext(HomeContext);

  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <QrCodeFormContainer title={t('layers.title')}>
      <FormGroup>
        <FormLabel>{t('layers.background')}</FormLabel>
        <Controller
          render={({ field }) => (
            <FormImageUpload
              disabled={loading}
              onChange={field.onChange}
              value={field.value}
            />
          )}
          control={control}
          name="background"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>{t('layers.foreground')}</FormLabel>
        <Controller
          render={({ field }) => (
            <FormImageUpload
              disabled={loading}
              onChange={field.onChange}
              value={field.value}
              removable
            />
          )}
          control={control}
          name="foreground"
        />
      </FormGroup>
    </QrCodeFormContainer>
  );
}

export default QrCodeFormLayers;
