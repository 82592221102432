import clsx from 'clsx';
import { useState } from 'react';
import { SketchPicker } from 'react-color';
import { useDetectClickOutside } from 'react-detect-click-outside';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  color: string;
  onChangeColor: (color: string) => void;
}

function FormColorPicker(props: Props) {
  const [show, setShow] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setShow(false) });

  function onChangeColor(color: any) {
    props.onChangeColor(color.hex);
    // setShow(false);
  }

  return (
    <div
      ref={ref}
      {...props}
      className={clsx(
        'relative w-[60px] h-[20px] border border-border rounded p-0.5 cursor-pointer font-normal',
        props.className
      )}
    >
      <div
        className="h-full"
        style={{ backgroundColor: props.color }}
        onClick={() => setShow(true)}
      ></div>
      {show && (
        <div className="absolute z-10">
          <SketchPicker onChange={onChangeColor} color={props.color} />
        </div>
      )}
    </div>
  );
}

export default FormColorPicker;
