import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

function Title(props: Props) {
  let className = clsx(`text-[#393939] text-xl font-semibold`, props.className);

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
}

export default Title;
