import clsx from 'clsx';
import styles from './index.module.css';

interface ModalProps {
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  closeClassName?: string;
  onClose: () => void;
}

function Modal(props: ModalProps) {
  return (
    <>
      <div
        className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-20"
        onClick={props.onClose}
      ></div>

      <div className={clsx(styles.box, props.className)}>
        <div className={clsx(props.closeClassName, 'p-2 flex justify-end')}>
          <img
            className="cursor-pointer"
            src="/images/close.svg"
            width="14"
            height="14"
            alt=""
            onClick={props.onClose}
          />
        </div>
        <div className={clsx(styles['box-content'], props.contentClassName)}>
          {props.children}
        </div>
      </div>
    </>
  );
}

export default Modal;
