import clsx from 'clsx';
import { useEffect } from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  color: 'danger';
  scrollContainerId?: string;
}

function Notification(props: Props) {
  let className = clsx(
    `px-5 py-4 text-white bg-danger font-medium rounded-md mb-7`,
    props.className
  );

  useEffect(() => {
    if (props.scrollContainerId) {
      document
        .getElementById(props.scrollContainerId)!
        .scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
}

export default Notification;
