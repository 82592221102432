import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { verifyApiKey } from '../../api/auth';
import Button from '../../components/shared/button';
import FormGroup from '../../components/shared/form/group';
import FormLabel from '../../components/shared/form/label';
import PasswordFormInput from '../../components/shared/form/password-input';
import Notification from '../../components/shared/notification';
import Title from '../../components/shared/title';
import AuthContext from '../../context/auth-context';
import NotAuthGuard from '../../guards/not-auth';
import { homeRoute } from '../../utils/routes';

const schema = yup
  .object()
  .shape({
    apiKey: yup.string().required(),
  })
  .required();

function LoginPage() {
  const { t } = useTranslation('login');
  const { saveApiKey } = useContext(AuthContext);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(values: any) {
    console.log('submitted', values);
    setLoading(true);
    setError(undefined);
    try {
      await verifyApiKey(values.apiKey);

      saveApiKey(values.apiKey);
      navigate(homeRoute);

      toast.success(t('success'), { duration: 3000 });
    } catch (err) {
      console.error('failed to login', err);
      if (axios.isAxiosError(err) && err.response!.status === 401) {
        setError(t('errors.invalidApiKey'));
      } else {
        setError(t('errors.unexpected'));
      }

      setValue('apiKey', '');
    }
    setLoading(false);
  }

  return (
    <NotAuthGuard>
      <div className="flex justify-center">
        <div className="w-full max-w-[500px] pt-24">
          {error && <Notification color="danger">{error}</Notification>}

          <Title className="mb-7">{t('title')}</Title>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="mb-9">
              <FormLabel>{t('apiKey.label')}</FormLabel>
              <PasswordFormInput
                {...register('apiKey')}
                placeholder={t('apiKey.placeholder')}
                disabled={loading}
                error={errors.apiKey !== undefined}
              />
            </FormGroup>
            <Button className="w-full" disabled={loading}>
              {t('submit')}
            </Button>
          </form>
        </div>
      </div>
    </NotAuthGuard>
  );
}

export default LoginPage;
