interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

function MaxWidthContainer(props: Props) {
  return (
    <div className="flex justify-center">
      <div className="w-full max-w-[1240px] max-xl:px-4">{props.children}</div>
    </div>
  );
}

export default MaxWidthContainer;
