import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { generateQrCodeSvgFromId } from '../../../../../api/qrcode';
import { QrCodeItem } from '../../../../../interfaces/qr-code.interface';
import { downloadSvg } from '../../../../../utils/utils';
import Button from '../../../../shared/button';
import FormGroup from '../../../../shared/form/group';
import FormInput from '../../../../shared/form/input';
import FormLabel from '../../../../shared/form/label';
import Modal from '../../../../shared/modal';
import Title from '../../../../shared/title';

const schema = yup
  .object()
  .shape({
    data: yup.string().required(),
  })
  .required();

function DownloadQrCodeModal(props: { item: QrCodeItem; onClose: () => void }) {
  const { t } = useTranslation('home');
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { data: props.item.previewData },
  });

  async function onSubmit(values: any) {
    console.log('submitted', values);
    setLoading(true);
    try {
      const svg = await generateQrCodeSvgFromId(props.item.PK, values.data);
      await downloadSvg(svg, props.item.name + '.svg');
    } catch (err: any) {
      toast.error(t('list.downloadModal.errors.download'), { duration: 3000 });
    }
    setLoading(false);
  }

  function onClose() {
    if (!loading) {
      props.onClose();
    }
  }

  return (
    <Modal className="min-w-[400px]" onClose={onClose}>
      <Title className="!mb-4">{t('list.downloadModal.title')}</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup className="!mb-6">
          <FormLabel>{t('list.downloadModal.data')}</FormLabel>
          <FormInput
            {...register('data')}
            error={errors.data !== undefined}
            disabled={loading}
          />
        </FormGroup>

        <Button className="w-full" disabled={loading}>
          {t('list.downloadModal.download')}
        </Button>
      </form>
    </Modal>
  );
}

export default DownloadQrCodeModal;
