import clsx from 'clsx';
import { useState } from 'react';
import { useScreenWidth } from '../../../../hooks/use-screen-width';
import Title from '../../../shared/title';
import { ReactComponent as CollapseIcon } from './collapse.svg';
import { ReactComponent as ExpandIcon } from './expand.svg';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  title: string;
}

function QrCodeFormContainer(props: Props) {
  const [expanded, setExpanded] = useState(false);
  const { width } = useScreenWidth();

  const onMobile = width < 1024;

  return (
    <div
      {...props}
      className={clsx('bg-white rounded-xl px-6 py-4', props.className)}
    >
      <div
        className="flex"
        onClick={() => (onMobile ? setExpanded(!expanded) : {})}
      >
        <Title>{props.title}</Title>
        <div className="hidden ml-auto pl-3 pt-1 mr-[-15px] max-lg:block">
          {!expanded && <ExpandIcon />}
          {expanded && <CollapseIcon />}
        </div>
      </div>
      {(!onMobile || expanded) && <div className="mt-7">{props.children}</div>}
    </div>
  );
}

export default QrCodeFormContainer;
