import axios, { AxiosProgressEvent } from 'axios';
import { UploadDataResponse } from '../interfaces/upload-data-response';

export const shortenId = (id: string): string => {
  return id.split('#').pop()!;
};

export const uploadToS3 = async (
  blob: any,
  signedPostData: UploadDataResponse,
  options: { onUploadProgress?: (progress: AxiosProgressEvent) => void } = {}
): Promise<string> => {
  const formData = new FormData();

  for (const field in signedPostData.fields) {
    formData.append(field, signedPostData.fields[field]);
  }

  formData.append('file', blob);

  await axios.post(signedPostData.url, formData, {
    onUploadProgress: options.onUploadProgress,
  });

  return signedPostData.fields.key;
};

export const urlToBlob = async (url: string) => {
  try {
    // Fetch the content of the Blob URL
    const response = await fetch(url, { cache: 'no-cache' });

    // Extract the Blob data
    const blob = await response.blob();

    return blob;
  } catch (error) {
    console.error('Error converting RL to Blob:', error);
    throw error;
  }
};

export const svgImagesToBase64 = async (svg: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svg, 'image/svg+xml');

  // Find all image elements
  const imageElements = doc.querySelectorAll('image');

  const promises: Promise<void>[] = [];

  // Convert image URLs to Base64
  imageElements.forEach(async (imageElement) => {
    const imageUrl = imageElement.getAttribute('xlink:href');
    if (imageUrl) {
      promises.push(
        // axios.get(imageUrl, { responseType: 'blob' }).then(
        //   (blob) =>
        //     new Promise<void>((resolve, reject) => {
        //       console.log('BOB???', blob);
        //       const reader = new FileReader();
        //       reader.onloadend = () => {
        //         const base64Data = reader.result as string;
        //         imageElement.setAttribute('xlink:href', base64Data);
        //         resolve();
        //       };
        //       reader.readAsDataURL(blob as any);
        //     })
        // )

        fetch(imageUrl, { cache: 'no-cache' })
          .then((res) => res.blob())
          .then(
            (blob) =>
              new Promise<void>((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  const base64Data = reader.result as string;
                  imageElement.setAttribute('xlink:href', base64Data);
                  resolve();
                };
                reader.readAsDataURL(blob);
              })
          )
      );
    }
  });

  await Promise.all(promises);

  return doc.documentElement.outerHTML;
};

export const svgToPng = async (svg: string, width: number, height: number) => {
  svg = await svgImagesToBase64(svg);

  const canvas = document.createElement('canvas');

  //Resize can break shadows
  canvas.width = width;
  canvas.height = height;
  canvas.style.width = width + 'px';
  canvas.style.height = height + 'px';

  const ctxt = canvas.getContext('2d');

  const img = document.createElement('img');
  img.setAttribute(
    'src',
    'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg)))
  );
  return new Promise((resolve) => {
    img.onload = () => {
      ctxt!.drawImage(img, 0, 0);
      const file = canvas.toBlob((blob) => resolve(blob));
    };
  });
};

export const downloadSvg = async (svg: string, fileName: string) => {
  const result = await svgImagesToBase64(svg);

  // Create a Blob object from the SVG string
  const blob = new Blob([result], { type: 'image/svg+xml' });

  // Create a data URL from the Blob
  const url = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName; // Set the download attribute with the desired file name

  // Append the link to the body and click it programmatically
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

// function promisifiedXHR(method: string, url: string, data = null) {
//   return new Promise((resolve, reject) => {
//     const xhr = new XMLHttpRequest();
//     xhr.responseType = "blob";
//     xhr.open(method, url);
//     xhr.onload = function() {
//       if (xhr.status >= 200 && xhr.status < 300) {
//         resolve(xhr.response);
//       } else {
//         reject(new Error(`XHR failed with status ${xhr.status}`));
//       }
//     };
//     xhr.onerror = function() {
//       reject(new Error('XHR failed'));
//     };
//     xhr.setRequestHeader('Content-Type', 'application/json');
//     xhr.send(data ? JSON.stringify(data) : null);
//   });
// }
