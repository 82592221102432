import clsx from 'clsx';
import { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import HomeContext from '../../../../context/home-context';
import { QrCodeParamFile } from '../../../../interfaces/qr-code.interface';
import { calculateMinQRVersion } from '../../../../utils/qrcode';
import { shortenId } from '../../../../utils/utils';
import FormCheckbox from '../../../shared/form/checkbox';
import FormColorPicker from '../../../shared/form/color-picker';
import FormGroup from '../../../shared/form/group';
import FormInput from '../../../shared/form/input';
import FormLabel from '../../../shared/form/label';
import FormSelect from '../../../shared/form/select';
import FormUploadBox from '../../../shared/form/upload-box';
import QrCodeFormContainer from '../container';

interface Props {}

function QrCodeFormParams(props: Props) {
  const { t } = useTranslation('home');
  const { loading, selectedQrCode } = useContext(HomeContext);

  const {
    register,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const [previewData, version] = watch(['previewData', 'version']);

  useEffect(() => {
    const minVersion = calculateMinQRVersion(previewData || '', 'H');

    if (version < minVersion) {
      setValue('version', minVersion, { shouldValidate: true });
    }
  }, [previewData, version]);

  function onUploadParams(file: File) {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const obj: QrCodeParamFile = JSON.parse(e.target!.result as string);

        setValue('version', obj.version);
        setValue('darkColor', obj.dark);
        setValue('dataDarkColor', obj.data_dark);
        setValue('dataLightColor', obj.data_light);

        toast.success(t('params.loaded'), { duration: 3000 });
      } catch (parseError) {
        toast.error(t('params.errors.load'), { duration: 3000 });
        console.error('Error parsing JSON:', parseError);
      }
    };

    reader.readAsText(file);

    console.log('uploaded', file);
  }

  return (
    <QrCodeFormContainer title={t('params.title')}>
      {selectedQrCode && (
        <FormGroup>
          <FormLabel>{t('params.id')}</FormLabel>
          <FormInput value={shortenId(selectedQrCode.PK)} disabled />
        </FormGroup>
      )}
      <FormGroup>
        <FormLabel>{t('params.name.label')}</FormLabel>
        <FormInput
          {...register('name')}
          placeholder={t('params.name.placeholder')}
          disabled={loading}
          error={errors.name !== undefined}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>{t('params.previewData')}</FormLabel>
        <FormInput
          {...register('previewData')}
          disabled={loading}
          error={errors.previewData !== undefined}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>{t('params.upload')}</FormLabel>
        <FormUploadBox
          fileTypes={['json']}
          disabled={loading}
          onUpload={onUploadParams}
        />
      </FormGroup>
      <FormGroup>
        <div className="flex items-center">
          <div className="border border-background flex-1"></div>
          <div className="px-3 text-sm font-light">{t('params.or')}</div>
          <div className="border border-background flex-1"></div>
        </div>
      </FormGroup>
      <FormGroup
        className={clsx(loading ? 'opacity-50 pointer-events-none' : undefined)}
      >
        <FormLabel>{t('params.manual')}</FormLabel>
        <div className="text-sm font-light space-y-3">
          <div className="flex space-x-6 items-center">
            <div>{t('params.version')}</div>
            <div className="flex space-x-6 items-center">
              <FormSelect className="w-[52px]" {...register('version')}>
                {Array.from(Array(40).keys()).map((i) => (
                  <option value={i + 1}>{i + 1}</option>
                ))}
              </FormSelect>
              <Controller
                render={({ field }) => (
                  <FormCheckbox
                    checkboxProps={{
                      onChange: (ev) => field.onChange(ev.target.checked),
                      checked: field.value === true,
                      name: 'min',
                    }}
                  >
                    {t('params.minVersion')}
                  </FormCheckbox>
                )}
                control={control}
                name="min"
              />
            </div>
            {/* <div className="flex space-x-4">
              {[6, 7].map((n) => (
                <Controller
                  render={({ field }) => (
                    <FormRadio
                      radioProps={{
                        name: 'version',
                        value: n,
                        onClick: () => field.onChange(n),
                        checked: field.value === n,
                      }}
                    >
                      {n}
                    </FormRadio>
                  )}
                  control={control}
                  name="version"
                />
              ))}
            </div> */}
          </div>
          <div className="grid grid-cols-[auto_1fr] gap-x-6 gap-y-3">
            {['darkColor', 'dataDarkColor', 'dataLightColor'].map((field) => (
              <>
                <div>{t('params.' + field)}</div>
                <div>
                  <Controller
                    render={({ field }) => (
                      <FormColorPicker
                        color={field.value}
                        onChangeColor={field.onChange}
                      />
                    )}
                    control={control}
                    name={field}
                  />
                </div>
              </>
            ))}
          </div>
        </div>
      </FormGroup>
    </QrCodeFormContainer>
  );
}

export default QrCodeFormParams;
