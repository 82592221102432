import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import HomeContext from '../../../context/home-context';
import QrCodeFormLayers from './layers';
import QrCodeFormParams from './params';
import QrCodeFormPreview from './preview';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
    background: yup.string().required(),
    foreground: yup.string(),
    previewData: yup.string().required(),
    version: yup.number().required(),
    darkColor: yup.string().required(),
    dataDarkColor: yup.string().required(),
    dataLightColor: yup.string().required(),
    min: yup.bool(),
  })
  .required();

function QrCodeForm(props: Props) {
  const { selectedQrCode } = useContext(HomeContext);
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      previewData: process.env.REACT_APP_DEFAULT_PREVIEW_DATA!,
      version: +process.env.REACT_APP_DEFAULT_QR_VERSION!,
      darkColor: process.env.REACT_APP_DEFAULT_DARK_COLOR!,
      dataDarkColor: process.env.REACT_APP_DEFAULT_DATA_DARK_COLOR!,
      dataLightColor: process.env.REACT_APP_DEFAULT_DATA_LIGHT_COLOR!,
    },
  });

  useEffect(() => {
    if (selectedQrCode) {
      methods.setValue('previewData', selectedQrCode.previewData, {
        shouldValidate: true,
      });
      methods.setValue('name', selectedQrCode.name, { shouldValidate: true });
      methods.setValue('background', selectedQrCode.background, {
        shouldValidate: true,
      });
      methods.setValue('foreground', selectedQrCode.foreground, {
        shouldValidate: true,
      });
      methods.setValue('version', selectedQrCode.version, {
        shouldValidate: true,
      });
      methods.setValue('darkColor', selectedQrCode.darkColor, {
        shouldValidate: true,
      });
      methods.setValue('dataDarkColor', selectedQrCode.dataDarkColor, {
        shouldValidate: true,
      });
      methods.setValue('dataLightColor', selectedQrCode.dataLightColor, {
        shouldValidate: true,
      });
      methods.setValue('min', selectedQrCode.min, {
        shouldValidate: true,
      });
    }
    // if (!selectedQrCode) {
    //   methods.setValue(
    //     'previewData',
    //     process.env.REACT_APP_DEFAULT_PREVIEW_DATA!
    //   );
    //   methods.setValue('version', +process.env.REACT_APP_DEFAULT_QR_VERSION!, {
    //     shouldValidate: true,
    //   });
    //   methods.setValue('darkColor', process.env.REACT_APP_DEFAULT_DARK_COLOR!, {
    //     shouldValidate: true,
    //   });
    //   methods.setValue(
    //     'dataDarkColor',
    //     process.env.REACT_APP_DEFAULT_DATA_DARK_COLOR!,
    //     { shouldValidate: true }
    //   );
    //   methods.setValue(
    //     'dataLightColor',
    //     process.env.REACT_APP_DEFAULT_DATA_LIGHT_COLOR!,
    //     { shouldValidate: true }
    //   );
    // } else {
    //   const previewData = methods.getValues('previewData');
    //   if (!previewData) {
    //     methods.setValue(
    //       'previewData',
    //       process.env.REACT_APP_DEFAULT_PREVIEW_DATA!,
    //       { shouldValidate: true }
    //     );
    //   }

    //   methods.setValue('name', selectedQrCode.name, { shouldValidate: true });
    //   methods.setValue('background', selectedQrCode.background, {
    //     shouldValidate: true,
    //   });
    //   methods.setValue('foreground', selectedQrCode.foreground, {
    //     shouldValidate: true,
    //   });
    //   methods.setValue('version', selectedQrCode.version, {
    //     shouldValidate: true,
    //   });
    //   methods.setValue('darkColor', selectedQrCode.darkColor, {
    //     shouldValidate: true,
    //   });
    //   methods.setValue('dataDarkColor', selectedQrCode.dataDarkColor, {
    //     shouldValidate: true,
    //   });
    //   methods.setValue('dataLightColor', selectedQrCode.dataLightColor, {
    //     shouldValidate: true,
    //   });
    // }
  }, [selectedQrCode]);

  return (
    <div
      {...props}
      className={clsx(
        'bg-background p-6 rounded-xl grid grid-cols-[1fr_532px_1fr] gap-3 max-xl:grid-cols-[1fr_1fr_1fr] max-lg:grid-cols-1',
        props.className
      )}
    >
      <FormProvider {...methods}>
        <QrCodeFormParams />
        <QrCodeFormPreview />
        <QrCodeFormLayers />
      </FormProvider>
      {props.children}
    </div>
  );
}

export default QrCodeForm;
