export const calculateMinQRVersion = (
  data: string,
  errorCorrection: 'L' | 'M' | 'Q' | 'H'
) => {
  const errorCorrectionLevels = {
    L: [
      17, 32, 53, 78, 106, 134, 154, 192, 230, 271, 321, 367, 425, 458, 520,
      586, 644, 718, 792, 858, 929, 1003, 1091, 1171, 1273, 1367, 1465, 1528,
      1628, 1732, 1840, 1952, 2068, 2188, 2303, 2431, 2563, 2699, 2809, 2953,
    ],
    M: [
      14, 26, 42, 62, 84, 106, 122, 152, 180, 213, 251, 287, 331, 362, 412, 450,
      504, 560, 624, 666, 711, 779, 857, 911, 997, 1059, 1125, 1190, 1264, 1370,
      1452, 1538, 1628, 1722, 1809, 1911, 1989, 2099, 2213, 2331,
    ],
    Q: [
      11, 20, 32, 46, 60, 74, 86, 108, 130, 151, 177, 203, 241, 258, 292, 322,
      364, 394, 442, 482, 509, 565, 611, 661, 715, 751, 805, 868, 908, 982,
      1030, 1112, 1168, 1228, 1283, 1351, 1423, 1499, 1579, 1663, 1725,
    ],
    H: [
      7, 13, 22, 32, 43, 53, 61, 74, 89, 108, 125, 149, 169, 189, 214, 236, 259,
      283, 317, 365, 397, 442, 488, 528, 572, 618, 672, 721, 784, 842, 902, 940,
      1002, 1066, 1132, 1201, 1273, 1347, 1417, 1496, 1577,
    ],
  };

  for (let version = 1; version <= 40; version++) {
    const dataCapacity = errorCorrectionLevels[errorCorrection][version - 1];
    if (dataCapacity >= data.length) {
      return version;
    }
  }
  return -1; // Indicates that the data length is too large for any QR version
};
