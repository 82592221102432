import myAxios from '../utils/my-axios';

export const verifyApiKey = async (apiKey: string): Promise<void> => {
  const { data } = await myAxios.get('/auth/verify-admin-api-key', {
    headers: {
      'x-api-key': apiKey,
    },
  });

  return data;
};
