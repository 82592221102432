import clsx from 'clsx';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  icon: string;
  iconClassName: string;
  onIconClick?: () => void;
}

function IconInputWrapper(props: Props) {
  let className = clsx('relative', props.className);

  function onIconClick() {
    if (props.onIconClick) {
      props.onIconClick();
    }
  }

  return (
    <div
      {...{
        ...props,
        icon: undefined,
        onIconClick: undefined,
        iconClassName: undefined,
      }}
      className={className}
    >
      {props.children}
      <div
        className={clsx('absolute', props.iconClassName)}
        onClick={onIconClick}
      >
        <img src={props.icon} />
      </div>
    </div>
  );
}

export default IconInputWrapper;
