import { createContext, useState } from 'react';
import { GetQrCodesResult, QrCode } from '../interfaces/qr-code.interface';

interface AuthContextType {
  selectedQrCode?: QrCode;
  setSelectedQrCode: (qrCode?: QrCode) => void;
  loading?: boolean;
  setLoading: (loading: boolean) => void;
  qrCodes?: GetQrCodesResult;
  setQrCodes: (result?: GetQrCodesResult) => void;
}

const HomeContext = createContext<AuthContextType>({
  setLoading: () => {},
  setSelectedQrCode: () => {},
  setQrCodes: () => {},
});

export function HomeContextProvider(props: any) {
  const [loading, setLoading] = useState(false);
  const [selectedQrCode, setSelectedQrCode] = useState<QrCode | undefined>();
  const [qrCodes, setQrCodes] = useState<GetQrCodesResult | undefined>();

  return (
    <HomeContext.Provider
      value={{
        selectedQrCode,
        setSelectedQrCode,
        loading,
        setLoading,
        qrCodes,
        setQrCodes,
      }}
    >
      {props.children}
    </HomeContext.Provider>
  );
}

export default HomeContext;
