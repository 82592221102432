import axios from 'axios';
import parse from 'html-react-parser';
import { useContext, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  createQrCode,
  editQrCode,
  generateQrCodeSvg,
  getQrCodeImageUploadData,
} from '../../../../api/qrcode';
import HomeContext from '../../../../context/home-context';
import { QrCode } from '../../../../interfaces/qr-code.interface';
import {
  downloadSvg,
  svgToPng,
  uploadToS3,
  urlToBlob,
} from '../../../../utils/utils';
import Button from '../../../shared/button';
import QrCodeFormContainer from '../container';

interface Props {}

function QrCodeFormPreview(props: Props) {
  const { t } = useTranslation('home');
  const [svg, setSvg] = useState<string | undefined>();
  const [generating, setGenerating] = useState(false);
  const timeoutRef = useRef<{ timeout: any; data: string }>();
  const {
    setLoading,
    loading,
    setSelectedQrCode,
    selectedQrCode,
    setQrCodes,
    qrCodes,
  } = useContext(HomeContext);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    control,
    watch,
    reset,
    formState: { errors, isValid },
  } = useFormContext();

  const formValues = watch([
    'previewData',
    'version',
    'background',
    'darkColor',
    'dataDarkColor',
    'dataLightColor',
    'foreground',
  ]);

  useEffect(() => {
    if (
      isValid &&
      (!timeoutRef.current ||
        timeoutRef.current.data !== JSON.stringify(formValues)) &&
      formValues[0] &&
      formValues[2]
    ) {
      console.log('attempt!');

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current.timeout);
      }

      timeoutRef.current = {
        data: JSON.stringify(formValues),
        timeout: setTimeout(() => {
          console.log('proceed!');
          generateSvg(formValues);
        }, 500),
      };
    }
  }, [formValues, isValid]);

  async function generateSvg(values: any) {
    setGenerating(true);
    try {
      const result = await generateQrCodeSvg(
        values[0],
        values[1],
        values[2],
        values[3],
        values[4],
        values[5],
        values[6]
      );

      setSvg(result);
    } catch (err) {
      console.error('failed to generate', err);

      toast.error(t('preview.errors.generate'), { duration: 3000 });
    }
    setGenerating(false);
  }

  async function onSave(values: any) {
    setLoading(true);
    try {
      const updatedValues = { ...values };
      const uploadData = await getQrCodeImageUploadData();
      const images = ['background', 'foreground', 'previewImage'];

      // upload images to s3
      for (let i = 0; i < images.length; i++) {
        const field = images[i];

        if (updatedValues[field] && field !== 'previewImage') {
          if (updatedValues[field].startsWith('blob:')) {
            const blob = await urlToBlob(updatedValues[field]);
            updatedValues[field] = await uploadToS3(blob, uploadData[i]);
            console.log(field + ' uploaded');
          } else {
            console.log('ignore', field);
            updatedValues[field] = undefined;
          }
        } else if (field === 'previewImage') {
          console.log('attempt preview IMage');
          const blob = await svgToPng(svg!, 800, 800);
          updatedValues[field] = await uploadToS3(blob, uploadData[i]);
          console.log('preview IMage ploaded');
        }
      }

      console.log('val', updatedValues);

      let qrCode: QrCode;

      if (selectedQrCode) {
        qrCode = await editQrCode(selectedQrCode.PK, {
          ...updatedValues,
          removeForeground: values.foreground === undefined,
        });

        setQrCodes(
          qrCodes
            ? {
                ...qrCodes,
                items: qrCodes.items.map((item) => {
                  if (item.PK === selectedQrCode.PK) {
                    return qrCode;
                  }

                  return item;
                }),
              }
            : undefined
        );
      } else {
        qrCode = await createQrCode(updatedValues);
        setQrCodes(
          qrCodes
            ? { ...qrCodes, items: [qrCode, ...qrCodes.items] }
            : undefined
        );
      }

      setSelectedQrCode(qrCode);

      toast.success(t('preview.success'), { duration: 3000 });
    } catch (err) {
      console.error('failed to save', err);
      if (
        axios.isAxiosError(err) &&
        err.response!.data.error === 'uploadError'
      ) {
        toast.error(t('preview.errors.upload'));
      } else if (
        axios.isAxiosError(err) &&
        err.response!.data.error === 'nameTaken'
      ) {
        toast.error(t('preview.errors.nameTaken'));
      } else {
        toast.error(t('preview.errors.create'));
      }
    }
    setLoading(false);
  }

  function onClear() {
    setSelectedQrCode(undefined);
    setSvg(undefined);
    reset();
  }

  async function onDownload() {
    setLoading(true);
    try {
      await downloadSvg(svg!, 'qrcode.svg');
    } catch (err) {
      console.error('failed to download', err);
      toast.error(t('preview.errors.download'), { duration: 3000 });
    }
    setLoading(false);
  }

  return (
    <QrCodeFormContainer title={t('preview.title')}>
      {svg && (
        <>
          <div className="border border-border mb-10">{parse(svg)}</div>
          <div className="flex flex-col space-y-3">
            <Button
              onClick={handleSubmit(onSave)}
              disabled={loading || generating}
            >
              {t('preview.save')}
            </Button>
            <Button
              variant="outline"
              onClick={onDownload}
              disabled={loading || generating}
            >
              {t('preview.download')}
            </Button>
            <Button
              color="warning"
              variant="outline"
              disabled={loading || generating}
              onClick={onClear}
            >
              {t('preview.clear')}
            </Button>
          </div>
        </>
      )}
    </QrCodeFormContainer>
  );
}

export default QrCodeFormPreview;
