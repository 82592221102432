import clsx from 'clsx';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import { ReactComponent as UploadIcon } from './upload.svg';

function FormUploadBox(props: {
  fileTypes: string[];
  className?: string;
  onUpload: (file: File) => void;
  text?: string;
  error?: boolean;
  disabled?: boolean;
  content?: any;
}) {
  const { t } = useTranslation('common');
  let className = clsx(
    'border border-dashed rounded-lg flex cursor-pointer',
    props.error ? 'border-red-500 bg-red-100' : 'border-border',
    props.disabled ? 'opacity-50' : undefined,
    props.className,
    styles.container
  );

  return (
    <FileUploader
      classes={className}
      handleChange={props.onUpload}
      types={props.fileTypes}
    >
      {!props.content && (
        <div className="flex flex-col items-center space-y-3 p-4 w-full">
          <UploadIcon />
          <div className="text-sm text-center font-light">
            {props.text || t('upload')}
          </div>
        </div>
      )}
      {props.content}
    </FileUploader>
  );
}

export default FormUploadBox;
