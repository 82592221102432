import clsx from 'clsx';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { animateScroll } from 'react-scroll';
import { viewQrCode } from '../../../../api/qrcode';
import HomeContext from '../../../../context/home-context';
import { QrCodeItem } from '../../../../interfaces/qr-code.interface';
import { shortenId } from '../../../../utils/utils';
import Button from '../../../shared/button';
import DownloadQrCodeModal from './download-modal';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  item: QrCodeItem;
}

function QrCodeListItem(props: Props) {
  const { t } = useTranslation('home');
  const { setSelectedQrCode } = useContext(HomeContext);

  const [loading, setLoading] = useState(false);
  const [showDownload, setShowDownload] = useState(false);

  async function loadQrCode() {
    setLoading(true);
    try {
      const qrCode = await viewQrCode(props.item.PK);

      setSelectedQrCode(qrCode);

      setTimeout(() => {
        animateScroll.scrollTo(100, {
          containerId: 'form',
          smooth: true,
          duration: 500,
        });
      }, 500);
    } catch (err) {
      toast.error('list.errors.view');
    }
    setLoading(false);
  }

  return (
    <div
      {...{ ...props, item: undefined }}
      className={clsx(
        'bg-white rounded-md p-4',
        loading ? 'opacity-50 pointer-events-none' : undefined,
        props.className
      )}
    >
      <img
        className="block border border-border mb-3 cursor-pointer"
        src={props.item.previewImage}
        alt={props.item.name}
        onClick={loadQrCode}
      />
      <div className="font-medium mb-2">{props.item.name}</div>
      <div className="text-sm font-light mb-3">{shortenId(props.item.PK)}</div>
      <Button className="w-full" onClick={() => setShowDownload(true)}>
        {t('list.download')}
      </Button>

      {showDownload && (
        <DownloadQrCodeModal
          item={props.item}
          onClose={() => setShowDownload(false)}
        />
      )}
    </div>
  );
}

export default QrCodeListItem;
