import { Element } from 'react-scroll';
import QrCodeForm from '../../components/home/form';
import QrCodeList from '../../components/home/list';
import { HomeContextProvider } from '../../context/home-context';
import AuthGuard from '../../guards/auth';

function HomePage() {
  return (
    <AuthGuard>
      <HomeContextProvider>
        <Element name="form" className="mb-8">
          <QrCodeForm />
        </Element>
        <QrCodeList />
      </HomeContextProvider>
    </AuthGuard>
  );
}

export default HomePage;
