import {
  GetQrCodesResult,
  QrCode,
  QrCodeFormData,
} from '../interfaces/qr-code.interface';
import { UploadDataResponse } from '../interfaces/upload-data-response';
import myAxios from '../utils/my-axios';
import { shortenId } from '../utils/utils';

export const getQrCodeImageUploadData = async (): Promise<
  UploadDataResponse[]
> => {
  const { data } = await myAxios.get(`/qrcodes/upload-data`);

  return data;
};

export const createQrCode = async (
  formData: QrCodeFormData
): Promise<QrCode> => {
  const { data } = await myAxios.post(`/qrcodes`, formData);

  return data;
};

export const editQrCode = async (
  id: string,
  formData: QrCodeFormData & { removeForeground: boolean }
): Promise<QrCode> => {
  const { data } = await myAxios.patch(`/qrcodes/${shortenId(id)}`, formData);

  return data;
};

export const viewQrCode = async (id: string): Promise<QrCode> => {
  const { data } = await myAxios.get(`/qrcodes/${shortenId(id)}`);

  return data;
};

export const generateQrCodeSvg = async (
  url: string,
  version: number,
  background: string,
  darkColor: string,
  dataDarkColor: string,
  dataLightColor: string,
  foreground?: string
): Promise<string> => {
  const params = {
    data: url,
    version,
    background,
    foreground,
    dark: darkColor,
    data_dark: dataDarkColor,
    data_light: dataLightColor,
  };

  console.log('generate with', params);
  const { data } = await myAxios.post(
    `${process.env.REACT_APP_GENERATOR_URL!}/generate`,
    {
      data: url,
      version,
      background,
      foreground,
      dark: darkColor,
      data_dark: dataDarkColor,
      data_light: dataLightColor,
    }
  );

  return data;
};

export const generateQrCodeSvgFromId = async (
  id: string,
  content: string
): Promise<string> => {
  const { data } = await myAxios.post(
    `${process.env.REACT_APP_GENERATOR_URL!}/${shortenId(id)}/generate`,
    {
      data: content,
    }
  );

  return data;
};

export const getQrCodes = async (options: {
  startKey?: string;
  search?: string;
}): Promise<GetQrCodesResult> => {
  const { data } = await myAxios.get(`/qrcodes`, {
    params: {
      search: options.search,
      startKey: options.startKey ? JSON.stringify(options.startKey) : undefined,
      sort: 'createdAt',
      reverse: true,
    },
  });

  return data;
};
