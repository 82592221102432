import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import MaxWidthContainer from './components/core/max-width-container';
import NavBar from './components/core/navbar';
import { AuthContextProvider } from './context/auth-context';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import { homeRoute, loginRoute } from './utils/routes';

function App() {
  return (
    <AuthContextProvider>
      <MaxWidthContainer>
        <NavBar />
        <div className="py-9 min-h-full max-sm:py-6">
          <Routes>
            <Route path={homeRoute} element={<HomePage />} />
            <Route path={loginRoute} element={<LoginPage />} />
          </Routes>
        </div>
      </MaxWidthContainer>
      <Toaster />
    </AuthContextProvider>
  );
}

export default App;
